export const Styles = Theme => ({
    markReadLink:{
        textAlign: "right",
        display:"block",
    },
    notificationsOuter: {
        "& ul": {
            margin: "20px 0",
        },
        "& li": {
            marginBottom: "20px",
        }
    },
    notBox: {
        padding: "15px 10px",
        borderRadius: "10px",
        background: "#f7f7f7",
        borderLeft: "3px solid #ffc200",
        "@media(max-width:991px)": {
            background: "#fff",
        },
        "& h4": {
            marginBottom: "10px",
        },
        "& p": {
            marginBottom: "10px",
        }
    },
    readNot: {
        borderLeft: "3px solid rgba(63, 68, 68, 0.3)",
    },
    dFlex: {
        display: "table",
        width:"100%",
        "& a": {
            marginBottom: "0",
            paddingRight: "10px",
            textAlign:"left",
            display:"table-cell",
            width:"50%",
        },
        "& p":{
            marginBottom: "0", 
            textAlign:"right",
            display:"table-cell",
            width:"50%",
        }
    },
})